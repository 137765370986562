import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useWallet } from 'use-wallet'
import imageRight from '../../assets/img/illustration_2.png'
import styled from 'styled-components'
import { 
  stakeRemit, 
  unstakeRemit, 
  getRemitStake, 
  getRemitAddress, 
  totalEarnedTokens,
  depositedTokens,
  claimDivs,
  getPendingDivs,

  getHolders,
  getTotalClaimedRewards,
  getStakingTime,
  getTotalSC20,
  stake
} from '../../remit/utils'
import { getBalanceNumber } from '../../utils/formatBalance'

import Button from '../../components/Button'
import Page from '../../components/Page'
import Spacer from '../../components/Spacer'
import WalletProviderModal from '../../components/WalletProviderModal'
import useModal from '../../hooks/useModal'
import useRemit from '../../hooks/useRemit'
import ProgressCountdown from '../../components/ProgressCountdown'

const Farms: React.FC = () => {

  const { path } = useRouteMatch()
  const { account } = useWallet()
  const Remit = useRemit()
  // const contractRemit = getRemitContract(Remit)
  // const allowance = useAllowance(contractRemit, "stake")
  // const { onApprove } = useApprove(contractRemit, contractAddresses.remitStake[networkId])
  const remitStakeContract = getRemitStake(Remit)

  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)
  const [stakeValue, setStakeValue] = useState('')
  const [totalStakingValue, setTotalStakingValue] = useState('')
  const [myBalance, setMyBalance] = useState(getTotalSC20(Remit))
  const [staked, setStaked] = useState('...SC20')
  const [totalEarned, setTotalEarned] = useState('...SC20')
  const [pending, setPending] = useState('...SC20')
  const [totalHolders, setHolders] = useState('')
  const [totalStaked, setTotalStaked] = useState('')
  const [stakingTime, setStakingTime] = useState(0)

  const getRemit = async () => {
    //  setStakeValue(getBalanceNumber(RemitBalance) ? getBalanceNumber(RemitBalance).toString() : 'NaN')
    setStakeValue((parseInt(getTotalSC20(Remit)) / 1000000000000000000).toFixed(3))
    setMyBalance(getTotalSC20(Remit))
  }

  const getStaking = async () => {
    let total = await depositedTokens(remitStakeContract, account)
     setTotalStakingValue(total ? getBalanceNumber(total).toString() : 'NaN') 
  }
 
  useEffect(() => {
    async function fetch() {

   
      let _setStaked = await depositedTokens(remitStakeContract, account);
    
      let _setTotalEarned = await totalEarnedTokens(remitStakeContract, account);
     
      let _setPending = await getPendingDivs(remitStakeContract, account);
   
      let _setTotalHolders = await getHolders(remitStakeContract);
   
      let _setTotalStaked = await getTotalClaimedRewards(remitStakeContract);
 
      let _setStakingTime = await getStakingTime(remitStakeContract, account);
  

      setStaked(getBalanceNumber(_setStaked).toString())
      setTotalEarned(getBalanceNumber(_setTotalEarned).toString())
      setPending(getBalanceNumber(_setPending).toString())
    
      setHolders(_setTotalHolders)
      setTotalStaked(getBalanceNumber(_setTotalStaked).toString())
      setStakingTime(_setStakingTime)


    }
    if (Remit) {
      fetch()
      setMyBalance(getTotalSC20(Remit))
    }
  }, [setMyBalance, setStaked, setTotalEarned, setPending, setStakeValue, remitStakeContract, Remit])
  
  const DAYS = 30; 
  const prevEpoch = useMemo(() => new Date(stakingTime * 1000), [stakingTime]);
  const nextEpoch = useMemo(() => new Date(new Date(stakingTime * 1000).setDate(new Date(stakingTime * 1000).getDate() + DAYS)), [stakingTime]);

  
  const DAYSUNSTAKE = 1825; 
  const prevEpochStake = useMemo(() => new Date(stakingTime * 1000), [stakingTime]);
  const nextEpochStake = useMemo(() => new Date(new Date(stakingTime * 1000).setDate(new Date(stakingTime * 1000).getDate() + DAYSUNSTAKE)), [stakingTime]);

  const data1 = new Date(prevEpoch);
  const data2 = new Date(nextEpoch);

      {/* @ts-ignore */}

  const diffEmMilissegundos = Math.abs(data2 - data1);

   async function fetchAfter() {
      let _setStaked = await depositedTokens(remitStakeContract, account)
      let _setTotalEarned = await totalEarnedTokens(remitStakeContract, account)
      let _setPending = await getPendingDivs(remitStakeContract, account)
    
      let _setTotalHolders = await getHolders(remitStakeContract)
      let _setTotalStaked = await getTotalClaimedRewards(remitStakeContract)
      let _setStakingTime = await getStakingTime(remitStakeContract, account)

      setStaked(getBalanceNumber(_setStaked).toString())
      setTotalEarned(getBalanceNumber(_setTotalEarned).toString())
      setPending(getBalanceNumber(_setPending).toString())

      setHolders(_setTotalHolders)
      setTotalStaked(getBalanceNumber(_setTotalStaked).toString())
      setStakingTime(_setStakingTime)

    }

    async function fetchAfterClaim() {
      let _setStaked = await depositedTokens(remitStakeContract, account)
      let _setTotalEarned = await totalEarnedTokens(remitStakeContract, account)
   
      let _setTotalHolders = await getHolders(remitStakeContract)
      let _setTotalStaked = await getTotalClaimedRewards(remitStakeContract)
      let _setStakingTime = await getStakingTime(remitStakeContract, account)

      setStaked(getBalanceNumber(_setStaked).toString())
      setTotalEarned(getBalanceNumber(_setTotalEarned).toString())
      setPending('0')
   
      setHolders(_setTotalHolders)
      setTotalStaked(getBalanceNumber(_setTotalStaked).toString())
      setStakingTime(_setStakingTime)
    }

  return (
    <Switch>
      <Page>
        {!!account ? (
          <>
            <Route exact path={path}>
            

              <MarginTop /> 

              <ContainerHome>
                <div>
                  <WrapperStake>
                    {/* <TitleStake2>20% SUSTAINABLE FIXED APR</TitleStake2> */}
                    <TitleStake2>Validator 5 Years - 5% FIXED PER MONTH</TitleStake2>
                  </WrapperStake>
                  <WrapperStake>
                    <TitleStake>Minimum lock time 5 years</TitleStake>
                  </WrapperStake>

                  <WrapperStake>
                    <ButtonStake
                      onClick={async () => (await stakeRemit(remitStakeContract, stakeValue, account) ? fetchAfter() : null)}
                    >
                      Stake
                    </ButtonStake>
                    <DivButtons>
                      <InputStake 
                        type="text" 
                        placeholder="o" 
                        value={stakeValue}
                        onChange={e => setStakeValue(e.target.value)}
                      />
                      <ButtonMax
                        onClick={async () => getRemit()}
                      >
                        max
                      </ButtonMax>
                    </DivButtons>
                  </WrapperStake>

                  <Spacer size="sm" />
                  <WrapperStake>
                    <TitleStake>Minimum deposit 1000 SC20</TitleStake>
                  </WrapperStake>


                  <WrapperStake>
                    {/* <SubTitleStake><b>0.5</b><Per>%</Per> fee for staking.</SubTitleStake> */}
                    {/* <SubTitleStake>Deposit the <b>SC20 </b>and earned more SC20</SubTitleStake> */}

                    
                  </WrapperStake>

                  <Spacer size="sm" />

                  <WrapperStake>
                    <ButtonStake
                      onClick={async () => (await unstakeRemit(remitStakeContract, totalStakingValue, account))}
                    >
                      Unstake
                    </ButtonStake>
                    <DivButtons>
                      <InputStake 
                        type="text" 
                        placeholder="o" 
                        value={totalStakingValue} 
                        onChange={e => setTotalStakingValue(e.target.value)}
                      />
                      <ButtonMax
                        onClick={ async () => getStaking()}
                      >
                        max
                      </ButtonMax>
                    </DivButtons>
                  </WrapperStake>

                  <WrapperStake>
                    {/* <SubTitleStake><b>0.5</b><Per>%</Per> fee for unstaking.</SubTitleStake> */}
                  </WrapperStake>

                  <Spacer size="sm" />
                  <WrapperStake>
            

                    <ProgressCountdown
                      base={prevEpochStake}
                      deadline={nextEpochStake}
                      description="Days Remaining to unstake"
                    />     
                  </WrapperStake>


                  <Spacer size="sm" />
                  <Spacer size="sm" />
                  <Spacer size="sm" />
                  <Spacer size="sm" />
                  <Spacer size="sm" />
     

                  <WrapperStake>
                    <ButtonClaim
                      // onClick={async () => await claimDivs(remitStakeContract, account)}
                      onClick={async () => (null)}

                    >
                      claim
                    </ButtonClaim>
           
                    {/* <div style={{ display: 'flex', minWidth: 250, color: '#fff' }}>
                      <span>...</span>
                    </div> */}

                    {/* <ProgressCountdown
                      base={prevEpoch}
                      deadline={nextEpoch}
                      description="Days Remaining to claim"
                    />      */}
                  </WrapperStake>

                  {/* <WrapperNote>
                    <WrapperStake style={{ paddingBottom: 15 }}>
                      <InfoNote>Please note: You can claim your earnings every 30 days</InfoNote>
                    </WrapperStake>
                  </WrapperNote> */}
                </div>

                <InfoContainer>

                  <div style={{ marginBottom: 20 }}>
                    <WrapperAPR style={{ paddingBottom: 10 }}>
                      <InfoAPR><span className="orange-bold">SC20</span> VALIDATOR</InfoAPR>
                    </WrapperAPR>
                    {/* <WrapperAPR style={{ paddingBottom: 10 }}>
                      <InfoAPR><span className="orange">APR-</span>Yearly:</InfoAPR>
                      <InfoResultAPR><span className="orange">240%</span></InfoResultAPR>
                    </WrapperAPR> */}
                    <WrapperAPR style={{ paddingBottom: 10 }}>
                      <InfoAPR><span className="orange">APR-</span>Monthly:</InfoAPR>
                      <InfoResultAPR><span className="orange">5%</span></InfoResultAPR>
                    </WrapperAPR>
                    <WrapperAPR style={{ paddingBottom: 10 }}>
                      
                    
                      <InfoAPR><span className="orange">APR-</span>Daily:</InfoAPR>
                      <InfoResultAPR><span className="orange">~0.166%</span></InfoResultAPR>
                    </WrapperAPR>
                  </div>

                  {/* <WrapperStake style={{ paddingBottom: 15 }}>
                    <Info>My Balance</Info>
                    <InfoResult>{myBalance ? (parseInt(myBalance) / 1000000000000000000).toFixed(3) : myBalance}</InfoResult>
                  </WrapperStake> */}
                  <WrapperStake style={{ paddingBottom: 15 }}>
                    <Info>Staked</Info>
                    <InfoResult>{staked.substring(0,8)}</InfoResult>
                  </WrapperStake>
                  {/* <WrapperStake style={{ paddingBottom: 15 }}>
                    <Info>Total Earned</Info>
                    <InfoResult>{totalEarned.substring(0,8)}</InfoResult>
                  </WrapperStake> */}
                  <WrapperStake style={{ paddingBottom: 15 }}>
                    <Info>Pending</Info>
                    <InfoResult>{pending.substring(0,8)}</InfoResult>
                  </WrapperStake>

                  {/* <div style={{ marginTop: 18, background: "#f2f9fe", borderRadius: 7, padding: "10px" }}>
                    <WrapperStake style={{ paddingBottom: 15 }}>
                      <Info>TVL</Info>
                      <InfoResult>...USD</InfoResult>
                    </WrapperStake>
                    <WrapperStake style={{ paddingBottom: 15 }}>
                      <Info>Total Supply</Info>
                      <InfoResult>{totalSupply.substring(0,6)} SC20</InfoResult>
                    </WrapperStake>
                    <WrapperStake style={{ paddingBottom: 15 }}>
                      <Info>Total Staked</Info>
                      <InfoResult>{totalStaked.substring(0,6)} SC20</InfoResult>
                    </WrapperStake>
                    <WrapperStake style={{ paddingBottom: 15 }}>
                      <Info>Total Stakers</Info>
                      <InfoResult>{totalHolders}</InfoResult>
                    </WrapperStake>
                    <WrapperStake style={{ paddingBottom: 15 }}>
                      <Info>Circulating Supply</Info>
                      <InfoResult>...SC20</InfoResult>
                    </WrapperStake>
                  </div> */}
                  
                </InfoContainer>
              </ContainerHome>

              {/* <Spacer size="md" /> */}

              <MarginBottom />

            </Route>

          </>
        ) : (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={onPresentWalletProviderModal}
              text="🔓 Unlock Wallet"
            />
          </div>
        )}
      </Page>
    </Switch>
  )
}

const Wrapper = styled.div`
  min-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  flex-wrap: wrap;

  @media(max-width: 800px) {
    min-width: 200px;
    flex-wrap: wrap;
  }
`

const WrapperSpan = styled.span`
  margin-top: 90px;
  width: 550px;

  @media(max-width: 800px) {
    /* color: #666666; */
    margin: 0 auto;
    margin-top: 0px;
    width: 330px;
  }
`

const WrapperStake = styled.div`
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media(max-width: 600px) {
    margin: 0 auto;
    min-width: 100px;
    max-width: 230px;
    font-size: 12px;
  }
`

const WrapperAPR = styled.div`
  max-width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;

`

const ButtonClaim = styled.div`
  background: #0096cd;
  width: 225px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background: #007daa;
    transition: all .35s;
  }
`

// const ButtonApprove = styled.div`
//   background-image: linear-gradient(#0048bf, #fff);
//   width: 225px;
//   height: 45px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #fff;
//   font-size: 18px;
//   font-weight: bold;
//   text-align: center;
//   border-radius: 5px;
//   text-transform: uppercase;
//   cursor: pointer;

//   &:hover{
//     background-color: #ec782b;
//     transition: all .35s;
//   }
// `

const ButtonStake = styled.div`
  background: #666666;
  width: 225px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background: #575757;
  }
`

const InputStake = styled.input`
  margin-left: 20px;
  width: 128px;
  height: 28px;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  color: #a3a7aa;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }

  @media(max-width: 600px) {
    margin-left: 0px;
    margin-top: 15px;

  }
`

const ButtonMax = styled.div`
  background: #666666;
  width: 64px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  text-transform: uppercase;
  border-radius: .3rem;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;

  &:hover {
    background: #575757;
  }

  @media(max-width: 600px) {
    margin-top: 15px;
  }
 
`

const TitleStake = styled.span`
  color: #fff;
  margin-bottom: 15px;
  /* margin-left: 30px; */
  font-size: 14px !important;
  
  @media(max-width: 600px) {
    margin-left: 0px;
  }
`

const TitleStake2 = styled.span`
  color: #00fedf;
  margin-bottom: 15px;
  /* margin-left: 30px; */
  font-size: 22px !important;
  font-weight: bold;
  
  @media(max-width: 600px) {
    margin-left: 0px;
  }
`

const SubTitleStake = styled.span`
  color: #bdbdbd;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 85px;
  font-size: 13px;

  @media(max-width: 600px) {
    margin: 20px auto;
  }
`


const Per = styled.span`
  color: #575757;
  font-weight: bold;
`

const Info = styled.span`
  color: #575757;
  font-weight: bold;
  font-size: 15px;
`

const InfoAPR = styled.span`
  color: #575757;
  font-weight: bold;
  font-size: 15px;

  .normal{
    font-weight: normal;
  }

  .orange{
    font-weight: normal;
    color: #fff;
  }

  .orange-bold{
    color: #fff;
    font-weight: bold;
  }

`

const InfoResult = styled.span`
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  
  .mobile{ 
    font-size: 14px;
  }
  
`

const InfoResultAPR = styled.span`
  color: #575757;
  font-size: 15px;

  .orange{
    color: #fff;
  }

`

const MarginTop = styled.div`
  margin-top: 50px;

  @media(max-width: 800px) {
    /* margin-top: 60px; */
  }

  @media(min-width: 1500px) {
    margin-top: 150px;
  }
`

const MarginBottom = styled.div`
  margin-bottom: 100px;

  @media(max-width: 800px) {
    
  }

  @media(min-width: 1500px) {
  
  }
`

const Container = styled.div`
  margin-top: 40px;

  @media(max-width: 800px) {
    /* margin-top: 60px; */
  }

  @media(min-width: 1500px) {
    margin-top: 140px;
  }

`

const IMAGE_1 = styled.img`
  width: 600px;
  height: 274px;

  @media(max-width: 800px) {
    margin: 0 auto;
    width: 340px;
    height: auto;
  }

  @media(min-width: 1500px) {
    width: 664px;
    height: 303px;
  }

`

const ContainerHome = styled.div`
  display: flex; 
  justify-content: space-between; 
  min-width: 1000px;
  flex-wrap: wrap;

  @media(max-width: 600px) {
    min-width: 360px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
  }

  @media(min-width: 1500px) {
    
  }

`

const InfoContainer = styled.div`

  @media(max-width: 600px) {
    margin: 30px auto;
    
  }

  @media(min-width: 1500px) {
    
  }

`

const DivButtons = styled.div`
  display: flex;
  min-width: 160px;

  @media(max-width: 600px) {
    
    
  }

  @media(min-width: 1500px) {
    
  }

`

const WrapperNote = styled.div`
  margin-top: 20px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-wrap: wrap;

  @media(max-width: 600px) {
    margin: 0 auto;
    margin-bottom: 10px;
    min-width: 100px;
    max-width: 230px;
  }
`

const InfoNote = styled.span`
  margin: 0 auto;
  color: #575757;
  font-weight: bold;
  font-size: 15px;
  max-width: 350px;
  text-align: center;
`

export default Farms;



// APR 30 % travar por 6 meses (2,5% ao mês)

// APR 36 % travar por 12 meses ( 3% ao mês)

// APR 42 % travar por 24 meses
// (3,5% ao mês)