
export const SUBTRACT_GAS_LIMIT = 100000

export const networkId = 11221

export const contractAddresses = {
  remit: {
    11221: '0x7f5DA3fd14a6A14f7ec6450324a9042121D66973',
  },
  weth: {
    11221: '0x7f5DA3fd14a6A14f7ec6450324a9042121D66973',
  },
  remitStake: {
    11221: '0x7f5DA3fd14a6A14f7ec6450324a9042121D66973',
  },
  remitVaultFarmEth: {
    11221: '0x7f5DA3fd14a6A14f7ec6450324a9042121D66973',
  },
  stake2: {
    11221: '0x5bDc5469CA71a122FC88c57b1e1536354dC34AC6',
  },
  stake3_5: {
    11221: '0x5827281910a58a9Ac300d39F1bD6Eec164A121A3',
  },
  stake3: {
    11221: '0xD3dd57fF03A314fa542C13e88098b188044ba144',
  },
}

export const supportedPools = []

export const farmingPools = { 
  remitETH: {
    11221: '0x7f5DA3fd14a6A14f7ec6450324a9042121D66973',
  }
}

