import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const getRemitPlatformAddress = (Remit) => {
  return Remit && Remit.remitPlatformAddress
}
export const getRemitAddress = (Remit) => {
  return Remit && Remit.RemitAddress
}

export const getTotalSC20 = (Remit) => {
  return Remit && Remit.totalBalanceSC20;
}

export const getWethContract = (Remit) => {
  return Remit && Remit.contracts && Remit.contracts.weth
}

export const getRemitPlatformContract = (Remit) => {
  return Remit && Remit.contracts && Remit.contracts.remitPlatform
}
export const getRemitContract = (Remit) => {
  return Remit && Remit.contracts && Remit.contracts.Remit
}

export const getRemitStake = (Remit) => {
  return Remit && Remit.contracts && Remit.contracts.remitStake
}

export const getStake10 = (Remit) => {
  return Remit && Remit.contracts && Remit.contracts.stake10
}

export const getStake3_5 = (Remit) => {
  return Remit && Remit.contracts && Remit.contracts.stake3_5
}

export const getStake3 = (Remit) => {
  return Remit && Remit.contracts && Remit.contracts.stake3
}

export const getStake2 = (Remit) => {
  return Remit && Remit.contracts && Remit.contracts.stake2
}


export const getFarmETH = (Remit) => {
  return Remit && Remit.contracts && Remit.contracts.remitVaultFarmEth
}

export const getLPETH = (Remit) => {
  return Remit && Remit.contracts && Remit.contracts.remitETH
}

export const getFarms = (Remit) => {
  return Remit
    ? Remit.contracts.pools.map(
        ({
          pid,
          name,
          symbol,
          icon,
          tokenAddress,
          tokenSymbol,
          tokenContract,
          lpAddress,
          lpContract,
        }) => ({
          pid,
          id: symbol,
          name,
          lpToken: symbol,
          lpTokenAddress: lpAddress,
          lpContract,
          tokenAddress,
          tokenSymbol,
          tokenContract,
          earnToken: 'Remmit',
          earnTokenAddress: Remit.contracts.Remit.options.address,
          icon,
        }),
      )
    : []
}

export const getPoolWeight = async (remitPlatformContract, pid) => {
  const { allocPoint } = await remitPlatformContract.methods.poolInfo(pid).call()
  const totalAllocPoint = await remitPlatformContract.methods
    .totalAllocPoint()
    .call()
  return new BigNumber(allocPoint).div(new BigNumber(totalAllocPoint))
}

export const getEarned = async (remitPlatformContract, pid, account) => {
  return remitPlatformContract.methods.pendingRemit(pid, account).call()
}

export const getTotalLPWethValue = async (
  remitPlatformContract,
  wethContract,
  lpContract,
  tokenContract,
  pid,
) => {
  // Get balance of the token address
  const tokenAmountWholeLP = await tokenContract.methods
    .balanceOf(lpContract.options.address)
    .call()
  const tokenDecimals = await tokenContract.methods.decimals().call()
  // Get the share of lpContract that remitPlatformContract owns
  const balance = await lpContract.methods
    .balanceOf(remitPlatformContract.options.address)
    .call()
  // Convert that into the portion of total lpContract = p1
  const totalSupply = await lpContract.methods.totalSupply().call()
  // Get total weth value for the lpContract = w1
  const lpContractWeth = await wethContract.methods
    .balanceOf(lpContract.options.address)
    .call()
  // Return p1 * w1 * 2
  const portionLp = new BigNumber(balance).div(new BigNumber(totalSupply))
  const lpWethWorth = new BigNumber(lpContractWeth)
  const totalLpWethValue = portionLp.times(lpWethWorth).times(new BigNumber(2))
  // Calculate
  const tokenAmount = new BigNumber(tokenAmountWholeLP)
    .times(portionLp)
    .div(new BigNumber(10).pow(tokenDecimals))

  const wethAmount = new BigNumber(lpContractWeth)
    .times(portionLp)
    .div(new BigNumber(10).pow(18))

  return {
    tokenAmount,
    wethAmount,
    totalWethValue: totalLpWethValue.div(new BigNumber(10).pow(18)),
    tokenPriceInWeth: wethAmount.div(tokenAmount),
    poolWeight: await getPoolWeight(remitPlatformContract, pid),
  }
}

export const approve = async (lpContract, remitPlatformContract, account) => {
  return lpContract.methods
    .approve(remitPlatformContract.options.address, ethers.constants.MaxUint256)
    .send({ from: account })
}

export const approveAddress = async (lpContract, address, account) => {
  return lpContract.methods
      .approve(address, ethers.constants.MaxUint256)
      .send({ from: account })
}

export const getRemitSupply = async (Remit) => {
  return new BigNumber(await Remit.contracts.Remit.methods.totalSupply().call())
}

export const getXRemitSupply = async (Remit) => {
  return new BigNumber(await Remit.contracts.xRemitStaking.methods.totalSupply().call())
}

export const stake = async (remitPlatformContract, pid, amount, account) => {
  return remitPlatformContract.methods
    .deposit(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
    )
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}

export const unstake = async (remitPlatformContract, pid, amount, account) => {
  return remitPlatformContract.methods
    .withdraw(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
    )
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}
export const harvest = async (remitPlatformContract, pid, account) => {
  return remitPlatformContract.methods
    .deposit(pid, '0')
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}

export const getStaked = async (remitPlatformContract, pid, account) => {
  try {
    const { amount } = await remitPlatformContract.methods
      .userInfo(pid, account)
      .call()
    return new BigNumber(amount)
  } catch {
    return new BigNumber(0)
  }
}

export const redeem = async (remitPlatformContract, account) => {
  let now = new Date().getTime() / 1000
  if (now >= 1597172400) {
    return remitPlatformContract.methods
      .exit()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        console.log(tx)
        return tx.transactionHash
      })
  } else {
    alert('pool not active')
  }
}

export const enter = async (contract, amount, account) => {
  debugger
  return contract.methods
      .enter(
          new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
      )
      .send({ from: account })
      .on('transactionHash', (tx) => {
        console.log(tx)
        return tx.transactionHash
      })
}

export const leave = async (contract, amount, account) => {
  return contract.methods
      .leave(
          new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
      )
      .send({ from: account })
      .on('transactionHash', (tx) => {
        console.log(tx)
        return tx.transactionHash
      })
}


// Stake Remit

// export const stakeRemit = async (contract, amount, account) => {
//   return contract?.methods
//       .deposit(
//           new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
//       )
//       .send({ from: account })
//       .on('transactionHash', (tx) => {
//         console.log(tx)
//         return tx.transactionHash
//       })
// }

export const stakeRemit = async (contract, amount, account) => {
  return contract?.methods
      .deposit()
      .send({ from: account, value: new BigNumber(amount).times(new BigNumber(10).pow(18)).toString() })
      .on('transactionHash', (tx) => {
        // console.log(tx)
        return tx.transactionHash
      })
}

export const unstakeRemit = async (contract, amount, account) => {
  return contract.methods
      .withdraw(
          new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
      )
      .send({ from: account })
      .on('transactionHash', (tx) => {
        console.log(tx)
        return tx.transactionHash
      })
}

export const totalEarnedTokens = async (contract, account) => {
  return new BigNumber(await contract?.methods.totalEarnedTokens(account).call())
}

export const depositedTokens = async (contract, account) => {
  return new BigNumber(await contract?.methods?.depositedTokens(account).call())
}

export const getPendingDivs = async (contract, account) => {
  return new BigNumber(await contract?.methods.getPendingDivs(account).call())
}

export const getTotalSupply = async (contract) => {
  return new BigNumber(await contract?.methods.totalSupply().call())
}

export const getHolders = async (contract) => {
  return await contract?.methods.getNumberOfHolders().call()
}

export const getTotalClaimedRewards = async (contract) => {
  return new BigNumber(await contract?.methods.totalClaimedRewards().call())
}

export const getTotalTokens = async (contract) => {
  return new BigNumber(await contract?.methods.totalTokens().call())
}

export const getPendingDivsUser = async (contract, account) => {
  return new BigNumber(await contract?.methods.getPendingDivsUser(account).call())
}

export const claimDivs = async (contract, account) => {
  return contract.methods
    .claimDivs()
    .send({ from: account })

  // return contract?.methods
  //   .deposit()
  //   .send({ from: account, value: '1000000000000000000' })
  //   .on('transactionHash', (tx) => {
  //     console.log(tx)
  //     return tx.transactionHash
  //   })
}

export const getStakingTime = async (contract, account) => {
  return await contract?.methods.stakingTime(account).call()
}

export const getDepositTime = async (contract, account) => {
  return await contract?.methods.depositTime(account).call()
}

export const claim = async (contract, account) => {
  return contract.methods
    .claim()
    .send({ from: account })
}


// Farm ETH

